<template>
  <v-card flat>
    <v-card-text
      v-if="error"
      class="mb-0"
    >
      <v-alert
        dense
        outlined
        border="left"
        type="error"
        class="mb-0"
      >
        {{ error.message }}
      </v-alert>
    </v-card-text>
    <v-card-text>
      <m-widget-container-form v-model="value" />
      <m-array-form
        v-model="value.floors"
        name="Floor"
        item-photo="image"
        item-title="title"
        :before-delete="beforeDeleteFloor"
      >
        <template
          #default="{ editItem }"
          @hook:mounted="!editItem.id && (editItem.id = uuidv1())"
        >
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="Floor Title"
          >
            <v-text-field
              v-model="editItem.title"
              outlined
              label="Floor Title"
              required
              :error-messages="errors"
            />
          </validation-provider>
          <field-card label="Floor Plan Image">
            <asset-manager v-model="editItem.image" />
          </field-card>
        </template>
      </m-array-form>
      <m-array-form
        v-model="value.rooms"
        name="Room"
        item-title="room"
        item-subtitle="title"
        item-photo="logo"
      >
        <template #default="{ editItem }">
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="Room Name"
          >
            <v-text-field
              v-model="editItem.room"
              label="Room Name"
              outlined
              required
              :error-messages="errors"
            />
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="Floor"
          >
            <v-select
              v-model="editItem.floorId"
              :items="value.floors"
              label="Floor"
              outlined
              required
              item-text="title"
              item-value="id"
              :error-messages="errors"
            />
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="Company Title"
          >
            <v-text-field
              v-model="editItem.title"
              label="Company Title"
              outlined
              required
              :error-messages="errors"
            />
          </validation-provider>
          <field-card label="Company Logo">
            <asset-manager v-model="editItem.logo" />
          </field-card>
          <v-textarea
            v-model="editItem.information"
            label="Company Information"
            auto-grow
            outlined
          />
          <v-text-field
            v-model="editItem.qrCode"
            label="QR Code Link"
            outlined
          />
          <v-text-field
            v-model="editItem.d"
            label="Room Path"
            outlined
            hint="This will be filled by your account manager."
            persistent-hint
            disabled
          />
        </template>
      </m-array-form>
      <field-card title="Design & Display">
        <v-switch
          v-model="value.horizontalLayout"
          label="Use Horizontal Layout"
        />
        <v-switch
          v-model="value.dark"
          label="Dark Mode"
          hint="Enable to make text colors white"
          persistent-hint
        />
        <m-color-input
          v-model="value.roomActiveColor"
          label="Active Room Color"
        />
        <v-text-field
          v-model="value.searchLabel"
          label="Search Text"
          outlined
          hint="You can use this field to customize the search field label."
        />
      </field-card>
    </v-card-text>
  </v-card>
</template>

<script>
import { v1 as uuidv1 } from 'uuid'
export default {
  name: 'MFloorPlanForm',
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    error: null
  }),
  methods: {
    uuidv1,
    beforeDeleteFloor({ id }) {
      const itemLen = this.value.rooms.filter(x => x.floorId === id).length
      if (itemLen) {
        if (itemLen === 1) {
          alert('There is one room still configured in this floor. Please delete it before deleting the floor.')
        } else {
          alert(`There are ${itemLen} rooms still configured in this floor. Please delete those before deleting the floor.`)
        }
        return false
      }
      return true
    }
  }
}
</script>
